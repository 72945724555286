 import logo from './logo.svg';
import './App.css';
import './pc/styles/main.css'
import AnimeCarousel from './pc/components/main';
 import React from "react";
function App() {
    const handleShareInviteLink = () => {
        const shareLink = `https://t.me/anime_funquiz_bot`;
        window.open(shareLink, '_blank');
    };

    const handleCopyInviteLink = () => {
        const inviteLink = `5PyMo7zbpdasusJR1GYmu43MYJ1GjniVdtGYczNcpump`;
        navigator.clipboard.writeText(inviteLink)
        const shareLink = `https://pump.fun/coin/${inviteLink}`;
        window.open(shareLink, '_blank');
    };
    const handleShareInviteLink2 = () => {
        const shareLink = `https://t.me/ANQZenjoyers`;
        window.open(shareLink, '_blank');
    };
    const handleShareInviteLink3 = () => {
        const shareLink = `https://x.com/animefunquiz`;
        window.open(shareLink, '_blank');
    };
  return (
      <div className="App">
          <div className="App-header">
              <div className="_footer_text _ca"
                   onClick={handleCopyInviteLink}>CA: &nbsp; &nbsp; 5PyMo7zbpdasusJR1GYmu43MYJ1GjniVdtGYczNcpump
              </div>
              <div className="_footer_text _coin">Anime World</div>
              <div className="_footer_text _play" style={{fontSize: "50px"}} onClick={handleShareInviteLink}>PLAY NOW
              </div>
          </div>
          <AnimeCarousel/>
          <div className="_carousel_text _footer_text" style={{fontSize:"2vw"}} onClick={handleShareInviteLink}>Guess The Anime</div>
          <div className="_sotials">
              <img onClick={handleShareInviteLink2}
                   src={`${process.env.PUBLIC_URL}/Telegram-removebg-preview.png`} className="_sotials_media"/>
              <img onClick={handleShareInviteLink3}
                   src={`${process.env.PUBLIC_URL}/twitter_x_new_logo_square_x_icon_256075-removebg-preview.png`}
                   className="_sotials_media"/>
          </div>

          <footer style={{
              color: "pink",
              background: "rgba(0,0,0,.8)",
              height: 60,
              placeContent: "center",
              placeItems: "center", marginLeft: "auto",
              marginRight: "auto", left: 0,
              right: 0, position: "absolute", bottom: 0,
          }}> AnimeFunQuiz
          </footer>

      </div>
  );
}

 export default App;
